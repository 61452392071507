import { useUI } from '@components/ui/context'
import useRemoveItem from '@framework/cart/use-remove-item'
import usePrice from '@framework/product/use-price'
import type { LineItem } from '@framework/types'
import cn from 'classnames'
import Image from 'next/legacy/image'
import Link from '@components/common/NoPrefetchLink'
import { useEffect, useState } from 'react'
import getName from '../getName'
import {
  ARTWORK_PROP_NAME,
  ARTWORK_PROP_PLACEHOLDER,
  getPropertiesToShow,
} from '../getPropertiesToShow'
import { removeInvalidFileUrls } from '@utils/utilities'
import { generateEditLink } from '@pageFeatures/design/legacyEditors.utils'
import { Button } from '@noissue-ui-kit/button'
import { isProductCustomisable } from '@components/product/ProductPage/ProductPage.utils'
import { CartItemQunatitySelector } from './CartItemQuantitySelector/CartItemQuantitySelector'
import { useAddItem } from '@framework/cart'
import { REORDER_FLOW_UPDATES_ENABLED } from '@utils/featureFlags'
import { useFeature } from '@growthbook/growthbook-react'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'
import { useCommerce } from '@commerce'
import { ArtworkStatusDot } from './ArtworkStatusDot'

const CartItem = ({
  item,
  cartSidebarData,
  currencyCode,
  setItemIsLoading,
  ...rest
}: {
  item: LineItem
  cartSidebarData: any
  currencyCode: string
  setItemIsLoading: (boolean) => void
}) => {
  const [isReorder, setIsReorder] = useState<boolean>()
  const [isStockProduct, setIsStockProduct] = useState<boolean>()
  const [openQuantitySelector, setOpenQuantitySelector] = useState<boolean>()
  const addItem = useAddItem()
  const removeItem = useRemoveItem()
  const [quantity, setQuantity] = useState(item.quantity)
  const [removing, setRemoving] = useState(false)
  const { closeSidebarIfPresent } = useUI()
  const reordeFlowUpdatesEnabled =
    useFeature(REORDER_FLOW_UPDATES_ENABLED)?.on &&
    useFeature(REORDER_FLOW_UPDATES_ENABLED)?.value[
      NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY
    ]
  const { locale } = useCommerce()
  const { price } = usePrice({
    amount: item.variant.price * item.quantity,
    baseAmount: item.variant.listPrice * item.quantity,
    currencyCode,
    locale,
  })

  const { price: originalPrice } = usePrice({
    currencyCode,
    amount: item.variant.originalPrice * item.quantity,
    locale,
  })

  const { price: listPrice } = usePrice({
    amount: item.variant.listPrice * item.quantity,
    currencyCode,
    locale,
  })

  const discounts = item.discounts.reduce(
    (total, current) => total + current.value,
    0
  )
  const discountedPrice = usePrice({
    amount: item.variant.listPrice - discounts,
    currencyCode,
    locale,
  })

  const handleRemove = async () => {
    setRemoving(true)
    try {
      // If this action succeeds then there's no need to do `setRemoving(true)`
      // because the component will be removed from the view
      await removeItem(item)
    } catch (error) {
      setRemoving(false)
    }
  }
  const options = (item as any).options
  const customizations = options.find(
    (option) => option.name === 'Customizations'
  )?.value

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
  }, [item.quantity])

  const editLink = generateEditLink(item)

  removeInvalidFileUrls(customizations)
  const propertiesToShow = getPropertiesToShow({
    item,
    customizations,
    optionsSchema: cartSidebarData.fields.propertiesToShow,
  })

  const itemName = getName({
    item,
    customizations,
    nameSuffixes: cartSidebarData.fields.newNameSuffixes,
    slugNameMapping: cartSidebarData.fields.slugNameMapping,
  })

  useEffect(() => {
    let isReorder = false
    if (item?.options) {
      isReorder = item?.options.some(
        (option) =>
          option.name?.toLowerCase() === 'reorder' && Boolean(option.value)
      )
    }
    setIsReorder(isReorder)
    setIsStockProduct(!isProductCustomisable(item?.categorySlug))
  }, [item])

  return (
    <li
      className={cn(
        'flex flex-row pb-8 border-b border-core-grey-40 last:mb-0 mt-16',
        {
          'opacity-75 pointer-events-none': removing,
        }
      )}
      {...rest}
    >
      <div className="w-full">
        <div className="flex justify-between w-full pb-2">
          <Link
            href={`/${item?.originalUrl?.split('/')?.slice(4, -1)?.join('/')}`}
          >
            <span
              className="block pr-8 text-2xl font-bold text-acai"
              onClick={() => closeSidebarIfPresent()}
            >
              {item.quantity && Number(item.quantity) > 1
                ? item.quantity + ' x '
                : ''}
              {itemName}&nbsp;{isReorder && <span>&#42;Reorder</span>}
            </span>
          </Link>
          <div className="text-2xl font-bold text-acai">
            {discounts > 0 ? (
              <div className="flex flex-col">
                <span>{discountedPrice.price}</span>
                <span className="line-through text-core-rose">{listPrice}</span>
              </div>
            ) : (
              <>
                {item.variant.price < item.variant.originalPrice ? (
                  <div className="flex flex-col">
                    <span>{price}</span>
                    <span className="line-through text-core-rose">
                      {originalPrice}
                    </span>
                  </div>
                ) : (
                  <span>{price}</span>
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-wrap items-start w-full mt-4">
          <div className="flex items-center justify-center w-32 sm:w-44">
            {editLink ? (
              <Link href={editLink}>
                <a>
                  <Image
                    onClick={() => closeSidebarIfPresent()}
                    className="flex justify-center w-full mt-4 mr-4 bg-white rounded-full shadow-sm"
                    width={150}
                    height={150}
                    src={item?.variant?.image?.url}
                    alt={item?.variant?.image?.altText}
                    unoptimized
                  />
                </a>
              </Link>
            ) : (
              <Image
                onClick={() => closeSidebarIfPresent()}
                className="flex justify-center w-full mt-4 mr-4 bg-white rounded-full shadow-sm"
                width={150}
                height={150}
                src={item?.variant?.image?.url}
                alt={item?.variant?.image?.altText}
                unoptimized
              />
            )}
          </div>
          {options && options.length > 0 ? (
            <>
              <div className="cartitemInfo w-[calc(100%-90px)] sm:w-[calc(100%-110px)] pl-8">
                <ul className="w-full">
                  {propertiesToShow.map((option: any, key: number) => {
                    return (
                      <li
                        key={key}
                        className="flex w-full mb-1 border-none font-mori"
                      >
                        <label className="inline-block m-0 text-lg font-semibold text-acai whitespace-nowrap">
                          {option.name} :
                        </label>
                        <span className="flex items-center m-0 ml-2 text-lg text-black text-opacity-20">
                          {option.name === ARTWORK_PROP_NAME && (
                            <ArtworkStatusDot
                              artworkUploaded={
                                option.value !== ARTWORK_PROP_PLACEHOLDER
                              }
                            />
                          )}
                          <span className="leading-none">{option.value}</span>
                        </span>
                      </li>
                    )
                  })}
                </ul>

                {
                  <div className="flex justify-between mt-6">
                    {!isStockProduct &&
                      editLink &&
                      (!isReorder || !reordeFlowUpdatesEnabled) && (
                        <Button
                          size="xs"
                          corners="rounded"
                          colour="acai"
                          variant="outline"
                          className="w-56 md:min-w-[180px] mr-6 font-mori font-semibold pt-4"
                          href={editLink}
                          Component="a"
                        >
                          {cartSidebarData.fields.editButtonLabel}
                        </Button>
                      )}

                    {(isReorder || isStockProduct) && reordeFlowUpdatesEnabled && (
                      <Button
                        size="xs"
                        corners="rounded"
                        colour="acai"
                        variant="outline"
                        className={`w-56 md:min-w-[180px] mr-6 font-mori font-semibold pt-4`}
                        onClick={() =>
                          setOpenQuantitySelector(!openQuantitySelector)
                        }
                        disabled={openQuantitySelector}
                      >
                        Edit quantity
                      </Button>
                    )}

                    <Button
                      size="xs"
                      corners="rounded"
                      colour="acai"
                      variant="outline"
                      className="bg-pebble !border-pebble font-mori font-semibold pt-4"
                      onClick={handleRemove}
                    >
                      Remove
                    </Button>
                  </div>
                }
              </div>

              <div
                className={`w-full transition-all mt-8 ${
                  openQuantitySelector
                    ? 'visible translate-y-0 opacity-100'
                    : 'hidden -translate-y-12 opacity-0'
                }`}
              >
                <CartItemQunatitySelector
                  cartItem={item}
                  onChange={async ({ options, variantId }) => {
                    const itemPayload = {
                      productId: String(item.productId),
                      variantId: String(variantId),
                      optionSelections: options.map((x) => {
                        return {
                          option_id: x.nameId,
                          option_value: JSON.stringify(x.value),
                        }
                      }),
                    }
                    setItemIsLoading(true)
                    await removeItem(item)
                    await addItem(itemPayload)
                    setItemIsLoading(false)
                  }}
                  shown={openQuantitySelector}
                />
                <Button
                  size="xs"
                  corners="rounded"
                  colour="acai"
                  variant="outline"
                  className="w-full pt-4 my-8 bg-pebble !border-pebble font-mori font-semibold"
                  onClick={() => setOpenQuantitySelector(false)}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </li>
  )
}

export default CartItem
