import React from 'react'
import { useSession } from 'next-auth/client'
import { NellieIcon } from '.'

export function UserNavButton({
  className,
  size = 'small',
}: {
  className?: string
  size?: 'small' | 'large'
}) {
  const [session, loading] = useSession()

  if (loading) {
    return null
  }

  const sizeToIconClass = {
    small: 'w-[35px] h-[35px]',
    large: 'w-24 h-24',
  }

  const sizeToTextClass = {
    small: 'text-xl',
    large: 'text-4xl',
  }

  const renderUserInitials = () => {
    if (session?.given_name && session?.family_name) {
      const initials =
        session?.given_name?.[0]?.toUpperCase() +
        session?.family_name?.[0]?.toUpperCase()

      return (
        <div
          className={`font-mori h-[35px] w-[35px] rounded-full text-boulder font-semibold leading-none flex justify-center items-center ${sizeToTextClass[size]}`}
        >
          <span>{initials}</span>
        </div>
      )
    } else {
      return <NellieIcon />
    }
  }

  return (
    <span
      className={`flex rounded-full items-center justify-center bg-unbleached ${className} ${sizeToIconClass[size]}`}
    >
      {session ? renderUserInitials() : <NellieIcon />}
    </span>
  )
}
