import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { IConfigurableText } from './ConfigurableText.types'
import {
  mapTextAlignToClass,
  mapTextColorToClass,
  mapTextSizeToClass,
  mapTextStyleToClass,
} from './ConfigurableText.utils'
import { useDevice } from '@utils/responsiveness/useDevice'
import classnames from 'classnames'
import { ReactElement } from 'react'

export function ConfigurableText({
  children = '',
  size = 'M',
  mobileSize = 'BASE',
  color = 'acai',
  style = 'normal',
  align = 'left',
  mobileAlign = 'left',
  className = '',
}: IConfigurableText): ReactElement {
  const { isMobile } = useDevice()

  if (!children) {
    return null
  }

  return (
    <ContentfulMarkdown
      overrideClass={classnames(
        isMobile
          ? mapTextAlignToClass[mobileAlign]
          : mapTextAlignToClass[align],
        isMobile ? mapTextSizeToClass[mobileSize] : mapTextSizeToClass[size],
        mapTextColorToClass[color],
        mapTextStyleToClass[style],
        className
      )}
    >
      {children}
    </ContentfulMarkdown>
  )
}
